import * as React from 'react';
import Media from 'react-media';

import { breakpoints } from '@coursera/cds-core';

import type { PropsFromWithUserAgent } from 'bundles/page/common/withUserAgent';

export const withMediaMaxWidthScreenTablet = <TProps extends {} & PropsFromWithUserAgent = {}>(
  Component: React.ComponentType<TProps & PropsFromWithMediaMaxWidthScreenTablet>
) => {
  const MediaHOC = (props: TProps) => {
    return (
      <Media query={{ maxWidth: breakpoints.values.sm - 1 }} defaultMatches={props.userAgent?.isMobileBrowser}>
        {(matches: boolean) => <Component {...props} matches={matches} />}
      </Media>
    );
  };
  return MediaHOC;
};

export type PropsFromWithMediaMaxWidthScreenTablet = {
  matches: boolean;
};
